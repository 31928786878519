import React, {useState, useEffect} from "react";

export default function FleecejackePrimoBack({
  lightContrast,
  darkContrast,
  stitching,
  patchColor,
  azomo,
  printStyle,
  getDataFromChild,
  preview,
}) {
  function adjust(color, amount) {
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
          ).substr(-2)
        )
    );
  }

  const [darkenColor, setDarkenColor] = useState(lightContrast);
  const [darkenColorContrast, setDarkenColorContrast] = useState(darkContrast);

  const [lightContrastActive, setLightContrastActive] = useState(false);
  const [darkContrastActive, setDarkContrastActive] = useState(false);
  const [stitchingActive, setStitchingActive] = useState(false);
  const [patchActive, setPatchActive] = useState(false);
  const [azomoActive, setAzomoActive] = useState(false);

  const handleClick = (tabId) => {
    if (!preview) {
      getDataFromChild(tabId);
    }
  };

  useEffect(() => {
    setDarkenColor(adjust(lightContrast, -50));
    setDarkenColorContrast(adjust(darkContrast, -100));
  }, [lightContrast, darkContrast]);

  return (
    <svg
      className={printStyle}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <g>
        <path
          fill={lightContrast}
          onClick={() => handleClick("base")}
          onMouseEnter={() => setLightContrastActive(true)}
          onMouseLeave={() => setLightContrastActive(false)}
          style={
            lightContrastActive && !preview
              ? {fill: adjust(lightContrast, 55)}
              : {fill: lightContrast}
          }
          d="M459.509,444.42l-0.32,0.009l0.32-0.019c-1.29-19.941-3.09-43.521-6.56-67.21
     c-1.36-9.3-2.26-18.771-3.13-27.92c-0.47-4.84-0.94-9.851-1.48-14.78c-0.42-3.84-1.21-7.71-1.96-11.451
     c-0.45-2.199-0.91-4.49-1.29-6.729c-1.65-9.761-3.41-19.72-5.23-29.61c-1.129-6.12-2.22-12.29-3.27-18.25
     c-3.859-21.951-7.52-42.68-13.47-64.1c-6.62-23.801-15.49-49.29-24.34-69.92c-4.41-10.301-10.41-19.73-13.63-24.79
     c-1.31-2.05-2.44-3.83-2.48-4.16l-0.23,0.01l0.1-0.1c-0.89-0.91-0.96-0.96-1.29-1.13c-0.16-0.08-0.39-0.2-0.9-0.551
     c-24.28-16.529-78.97-32.729-83.35-34.019c0.31-2.061,1.84-14.07-3.78-19.2c-6.81-6.22-23.27-12.68-43.46-12.29
     c-20.19-0.411-36.66,6.07-43.47,12.29c-5.62,5.13-4.09,17.139-3.78,19.2c-4.38,1.29-59.07,17.49-83.35,34.019
     c-0.51,0.351-0.74,0.471-0.889,0.551c-0.331,0.17-0.411,0.22-1.29,1.13l0.089,0.1l-0.22-0.01c-0.05,0.33-1.18,2.11-2.48,4.16
     c-3.23,5.06-9.23,14.489-13.639,24.79c-8.841,20.63-17.71,46.119-24.331,69.92c-5.96,21.41-9.61,42.14-13.48,64.08
     c-1.05,5.97-2.13,12.14-3.26,18.27c-1.83,9.91-3.59,19.87-5.24,29.61c-0.38,2.239-0.84,4.53-1.28,6.739
     c-0.76,3.74-1.54,7.601-1.96,11.441c-0.54,4.92-1.02,9.92-1.48,14.75c-0.87,9.16-1.78,18.639-3.14,27.95
     c-3.46,23.68-5.26,47.269-6.56,67.21l0.33,0.019h-0.33c0,0.08-0.02,0.671-0.02,0.671l-0.01,0.24l0.23,0.08
     c20.04,6.92,33.72,8.78,42.65,8.78c4.59,0,7.93-0.49,10.23-1.04c0.03-0.021,0.81-0.39,1.14-1.401
     c0.09-0.479,8.08-48.149,10.96-56.2c2.44-6.8,18.73-66.949,20.82-78.5c1.2-6.559,6.5-25.279,11.17-41.789
     c1.33-4.69,2.601-9.18,3.7-13.131c1.7,37.271,4.07,94.981,3.3,111.571c-1.149,25.009-1.32,56.049-1.32,56.41
     c0.14,1.019,0.49,1.929,0.81,2.689c5.31,12.281,22.63,18.17,34.09,22.071l0.231,0.08c14.599,4.96,30.319,8.33,46.729,10.019
     c8.37,0.861,16.95,1.28,25.71,1.28c13.07,0,26.53-0.95,40.24-2.849c25.91-3.601,44.2-10.38,57.58-21.32
     c3.63-2.97,8.46-7.451,8.43-12.02c0-0.271-0.16-31.11-1.33-56.36c-0.76-16.59,1.61-74.29,3.3-111.56
     c1.1,3.93,2.37,8.409,3.69,13.09c4.68,16.519,9.99,35.26,11.18,41.819c2.1,11.551,18.39,71.7,20.82,78.5
     c2.88,8.051,10.88,55.721,10.97,56.24c0.32,0.971,1.1,1.34,1.2,1.38c2.271,0.54,5.58,1.031,10.14,1.031
     c8.91,0,22.6-1.86,42.68-8.79l0.22-0.08L459.509,444.42z"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.45"
          strokeMiterlimit="10"
          strokeDasharray="1.343,1.342"
          d="M41.528,429.797
     c0,0,25.385,8.883,54.204,9.415"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.45"
          strokeMiterlimit="10"
          strokeDasharray="1.343,1.342"
          d="M457.988,429.62
     c0,0-25.384,8.884-54.203,9.416"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.45"
          strokeMiterlimit="10"
          strokeDasharray="1.343,1.342"
          d="M294.966,67.199
     c-6.314-1.123-24.37-4.033-41.879-4.033c-1.01,0-2.015,0.01-3.017,0.029c-0.92-0.019-1.929-0.029-2.937-0.029
     c-17.555,0-35.563,2.908-41.868,4.032"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.45"
          strokeMiterlimit="10"
          strokeDasharray="1.343,1.342"
          d="M205.56,54.846
     c0.666-0.778,2.879-2.394,2.879-2.394c4.362-3.991,18.163-11.511,39.321-11.61v0.013l2.71,0.01h2v-0.022
     c21.166,0.107,34.962,7.621,39.32,11.607c0,0,2.186,2.006,2.63,2.868"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.45"
          strokeMiterlimit="10"
          strokeDasharray="1.343,1.342"
          d="M119.862,106.39
     c1.22,6.58,6.683,36.229,12.121,66.349l0.633,4.157c5.415,28.687,11.025,56.822,11.417,86.168c0,0-0.627,0.788-1.677,1.078"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.45"
          strokeMiterlimit="10"
          strokeDasharray="1.343,1.342"
          d="
     M379.572,106.387c-2.098,11.382-7.144,38.817-12.098,66.235l-1.087,4.274c-5.416,28.687-11.025,56.822-11.417,86.168
     c0,0,0.626,0.788,1.677,1.078"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.45"
          strokeMiterlimit="10"
          strokeDasharray="1.343,1.342"
          d="
     M355.341,425.138c-1.66,3.246-5.045,6.198-7.327,8.064c-15.928,13.029-37.427,18.463-57.414,21.241
     c-21.795,3.028-43.967,3.818-65.876,1.571c-15.851-1.625-31.561-4.868-46.654-10.003c-11.311-3.848-28.314-9.565-33.873-21.416"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.45"
          strokeMiterlimit="10"
          strokeDasharray="1.343,1.342"
          d="
     M355.341,421.839c-1.66,3.246-5.045,6.198-7.327,8.064c-15.928,13.028-37.427,18.463-57.414,21.241
     c-21.795,3.028-43.967,3.817-65.876,1.571c-15.851-1.626-31.561-4.869-46.654-10.003c-11.311-3.849-28.314-9.565-33.873-21.416"
        />
        <path
          fill="#010101"
          d="M459.509,444.42l-0.32,0.009l0.32-0.019c-1.29-19.941-3.09-43.521-6.56-67.21
     c-1.36-9.3-2.26-18.771-3.13-27.92c-0.47-4.84-0.94-9.851-1.48-14.78c-0.42-3.84-1.21-7.71-1.96-11.451
     c-0.45-2.199-0.91-4.49-1.29-6.729c-1.65-9.761-3.41-19.72-5.23-29.61c-1.129-6.12-2.22-12.29-3.27-18.25
     c-3.859-21.951-7.52-42.68-13.47-64.1c-6.62-23.801-15.49-49.29-24.34-69.92c-4.41-10.301-10.41-19.73-13.63-24.79
     c-1.31-2.05-2.44-3.83-2.48-4.16l-0.23,0.01l0.1-0.1c-0.89-0.91-0.96-0.96-1.29-1.13c-0.16-0.08-0.39-0.2-0.9-0.551
     c-24.28-16.529-78.97-32.729-83.35-34.019c0.31-2.061,1.84-14.07-3.78-19.2c-6.81-6.22-23.27-12.68-43.46-12.29
     c-20.19-0.411-36.66,6.07-43.47,12.29c-5.62,5.13-4.09,17.139-3.78,19.2c-4.38,1.29-59.07,17.49-83.35,34.019
     c-0.51,0.351-0.74,0.471-0.889,0.551c-0.331,0.17-0.411,0.22-1.29,1.13l0.089,0.1l-0.22-0.01c-0.05,0.33-1.18,2.11-2.48,4.16
     c-3.23,5.06-9.23,14.489-13.639,24.79c-8.841,20.63-17.71,46.119-24.331,69.92c-5.96,21.41-9.61,42.14-13.48,64.08
     c-1.05,5.97-2.13,12.14-3.26,18.27c-1.83,9.91-3.59,19.87-5.24,29.61c-0.38,2.239-0.84,4.53-1.28,6.739
     c-0.76,3.74-1.54,7.601-1.96,11.441c-0.54,4.92-1.02,9.92-1.48,14.75c-0.87,9.16-1.78,18.639-3.14,27.95
     c-3.46,23.68-5.26,47.269-6.56,67.21l0.33,0.019h-0.33c0,0.08-0.02,0.671-0.02,0.671l-0.01,0.24l0.23,0.08
     c20.04,6.92,33.72,8.78,42.65,8.78c4.59,0,7.93-0.49,10.23-1.04c0.03-0.021,0.81-0.39,1.14-1.401
     c0.09-0.479,8.08-48.149,10.96-56.2c2.44-6.8,18.73-66.949,20.82-78.5c1.2-6.559,6.5-25.279,11.17-41.789
     c1.33-4.69,2.601-9.18,3.7-13.131c1.7,37.271,4.07,94.981,3.3,111.571c-1.149,25.009-1.32,56.049-1.32,56.41
     c0.14,1.019,0.49,1.929,0.81,2.689c5.31,12.281,22.63,18.17,34.09,22.071l0.231,0.08c14.599,4.96,30.319,8.33,46.729,10.019
     c8.37,0.861,16.95,1.28,25.71,1.28c13.07,0,26.53-0.95,40.24-2.849c25.91-3.601,44.2-10.38,57.58-21.32
     c3.63-2.97,8.46-7.451,8.43-12.02c0-0.271-0.16-31.11-1.33-56.36c-0.76-16.59,1.61-74.29,3.3-111.56
     c1.1,3.93,2.37,8.409,3.69,13.09c4.68,16.519,9.99,35.26,11.18,41.819c2.1,11.551,18.39,71.7,20.82,78.5
     c2.88,8.051,10.88,55.721,10.97,56.24c0.32,0.971,1.1,1.34,1.2,1.38c2.271,0.54,5.58,1.031,10.14,1.031
     c8.91,0,22.6-1.86,42.68-8.79l0.22-0.08L459.509,444.42z M206.73,50.979c4.799-4.389,19.849-12.519,42.669-12.119l0.01,0.01
     c0.12,0,0.23,0,0.35-0.01c0.12,0.01,0.23,0.01,0.35,0.01v-0.01c22.81-0.391,37.871,7.73,42.67,12.119
     c5.27,4.811,3.88,16.481,3.58,18.57c-3.01-0.57-25.76-4.769-46.6-4.359c-20.83-0.41-43.6,3.789-46.61,4.359
     C202.849,67.46,201.459,55.79,206.73,50.979 M93.599,451.6c-0.23,0.659-0.74,0.94-0.74,0.949c-6.61,1.581-22.18,2.67-52.22-7.66
     c0-0.179,0.01-0.389,0.01-0.449c0.26-3.92,0.53-8,0.84-12.181c1.24,0.431,26.02,8.811,53.81,9.401
     C94.279,447.479,93.619,451.479,93.599,451.6 M136.549,275.09c-4.68,16.53-9.99,35.26-11.18,41.85
     c-2.099,11.539-18.37,71.609-20.8,78.41c-2.04,5.699-6.61,31.01-9.16,45.67c-28.3-0.591-53.52-9.301-53.77-9.39l-0.14,0.379
     c1.24-16.979,2.94-35.819,5.7-54.719c1.37-9.321,2.27-18.811,3.15-27.981c0.46-4.83,0.93-9.819,1.47-14.739
     c0.42-3.811,1.201-7.66,1.951-11.38c0.449-2.221,0.909-4.511,1.299-6.761c1.64-9.739,3.4-19.7,5.23-29.609
     c1.13-6.12,2.21-12.3,3.27-18.271c3.86-21.929,7.51-42.639,13.451-64.009c6.619-23.781,15.479-49.241,24.309-69.85
     c4.39-10.24,10.37-19.641,13.59-24.69c1.2-1.89,1.91-3.021,2.27-3.691c1.6,8.67,16.74,90.731,22.12,122.95
     c0.03,0.55,0.66,13.141,1.47,30.8C139.559,264.44,138.089,269.63,136.549,275.09 M354.699,373.74
     c1.16,25.319,1.321,56.03,1.321,56.33c0.019,4.3-4.671,8.63-8.201,11.52c-13.28,10.86-31.47,17.589-57.25,21.169
     c-22.85,3.181-44.99,3.701-65.799,1.571c-16.361-1.68-32.031-5.04-46.581-9.99l-0.23-0.07c-11.35-3.87-28.51-9.7-33.7-21.721
     c-0.31-0.709-0.64-1.57-0.77-2.479c0-0.311,0.18-31.33,1.33-56.33c1.15-25.021-4.799-143.34-4.86-144.53l-0.33,0.009l0.32-0.049
     c-5.55-33.27-21.52-119.75-22.23-123.59c0.58-0.601,0.63-0.63,0.85-0.73c0.161-0.091,0.411-0.22,0.96-0.591
     c25.04-17.049,82.67-33.79,83.43-34.009c0.55-0.111,24.54-4.81,46.44-4.41l0.01,0.01c0.111,0,0.23,0,0.35-0.01
     c0.12,0.01,0.23,0.01,0.35,0.01v-0.01c21.93-0.39,45.9,4.299,46.44,4.41c0.77,0.219,58.39,16.96,83.44,34.009
     c0.54,0.371,0.79,0.5,0.96,0.591c0.21,0.1,0.27,0.129,0.85,0.73c-0.71,3.84-16.68,90.32-22.24,123.59l0.32,0.049l-0.32-0.009
     C359.499,230.4,353.539,348.719,354.699,373.74 M394.939,395.35c-2.43-6.801-18.69-66.871-20.79-78.41
     c-1.2-6.59-6.51-25.34-11.19-41.881c-1.55-5.449-3.01-10.63-4.22-15c0.8-17.659,1.44-30.25,1.46-30.8
     c5.39-32.219,20.52-114.28,22.12-122.95c0.37,0.67,1.08,1.801,2.28,3.691c3.21,5.049,9.2,14.45,13.59,24.69
     c8.831,20.609,17.69,46.069,24.3,69.85c5.95,21.38,9.6,42.099,13.46,64.03c1.05,5.97,2.14,12.13,3.27,18.25
     c1.82,9.89,3.58,19.85,5.23,29.609c0.38,2.25,0.84,4.54,1.29,6.75c0.75,3.731,1.531,7.58,1.95,11.391
     c0.541,4.93,1.02,9.93,1.48,14.77c0.87,9.16,1.78,18.629,3.14,27.95c2.75,18.78,4.44,37.49,5.68,54.37
     c-1.75,0.59-26.38,8.83-53.88,9.36C401.549,426.35,396.989,401.049,394.939,395.35 M406.709,452.57c0,0-0.56-0.28-0.78-0.92
     c-0.02-0.141-0.69-4.15-1.71-9.98c26.95-0.54,51.12-8.43,53.81-9.34c0.31,4.16,0.58,8.21,0.83,12.11l0.02,0.449
     C428.839,455.229,413.27,454.13,406.709,452.57"
        />
      </g>
    </svg>
  );
}
